// Form processing with recaptcha and messaging
$('#contactForm').submit(function (event) {
  event.preventDefault();
  $('.submitButton').prop('disabled', true);
  grecaptcha.reset();
  grecaptcha.execute();
});

function formSubmit(response) {
  document.getElementById('contactForm').submit();
}

var messagetimer;

function MessageBanner(title, message, type) {

    clearTimeout(messagetimer);
    // $('.toaster').hide();
    $('.toaster').removeClass("error, thanks");

    if (type == 'error') {
        $('.toaster').addClass("error");
    } else {
        $('.toaster').addClass("thanks");
    }

    $('.toaster__title').html(title);
    $('.toaster__content').html(message);
    $('.toaster').slideDown(300);

    messagetimer = setTimeout(function() {

        $('.toaster').fadeOut(800);
        window.history.replaceState(null, null, window.location.pathname);

    }, 5000);

}

// Decode the URL string
// ***************
function getParameterByName(name, url) {
    if (!url) {
        url = window.location.href;
    }
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}

var message = getParameterByName("message");

if(message == 'failed'){
  MessageBanner('Sorry.', 'There has been a problem, please try again...', 'error');
}
if(message == 'success'){
  MessageBanner('Thank You.', 'Your email has been sent.', 'thanks');
}



$('.faq__question').click(function(){
  $(this).toggleClass('open');
  $(this).next('.faq__answer').toggleClass('open');
  return false;
});

var pageRef = $('.content-main').attr("id");
$('.global-nav__item.'+ pageRef).addClass('active');

$('.global-nav__item.active').on('click', function(){
  return false;
});

$('.scrollLink').on('click', function() {

    var scrollOffSet = -100;

    var scrollAnchorUrl = $(this).attr('href');
    var scrollAnchorId = scrollAnchorUrl.split("#");
    var scrollAnchor = scrollAnchorId [scrollAnchorId.length-1];
    var scrollPoint = $('#' + scrollAnchor + '').offset().top + scrollOffSet;

    $('body,html').animate({
        scrollTop: scrollPoint
    }, 500);

    return false;

});

$(window).scroll(function() {

  var windscroll = $(window).scrollTop();

  var distbottom = $(document).height() - ($(window).height() + $('body').scrollTop());

  if (windscroll >= 230) {
    $('body').addClass('scroll-nav');
  } else {
    $('body').removeClass('scroll-nav');
  }

  if (windscroll >= 250) {
    $('.global-header').addClass('on');
    $('.global-header').removeClass('off');
  } else {
    $('.global-header').addClass('off');
    $('.global-header').removeClass('on');
  }

}).scroll();

// Instagram Feed Images
fetch(`https://graph.instagram.com/me/media?fields=media_url,permalink&access_token=${InstagramToken}`)
  .then(response => response.json())
  .then(data => buildGallery(data.data))
  .catch(error => {
    console.error('Error:', error);
    if(pageRef == 'home'){
      document.querySelector('.instagram-gallery').innerHTML = `<div class="instagram-placeholder">
      <svg viewBox="0 0 24 24">
          <path d="M7.8,2H16.2C19.4,2 22,4.6 22,7.8V16.2A5.8,5.8 0 0,1 16.2,22H7.8C4.6,22 2,19.4 2,16.2V7.8A5.8,5.8 0 0,1 7.8,2M7.6,4A3.6,3.6 0 0,0 4,7.6V16.4C4,18.39 5.61,20 7.6,20H16.4A3.6,3.6 0 0,0 20,16.4V7.6C20,5.61 18.39,4 16.4,4H7.6M17.25,5.5A1.25,1.25 0 0,1 18.5,6.75A1.25,1.25 0 0,1 17.25,8A1.25,1.25 0 0,1 16,6.75A1.25,1.25 0 0,1 17.25,5.5M12,7A5,5 0 0,1 17,12A5,5 0 0,1 12,17A5,5 0 0,1 7,12A5,5 0 0,1 12,7M12,9A3,3 0 0,0 9,12A3,3 0 0,0 12,15A3,3 0 0,0 15,12A3,3 0 0,0 12,9Z" />
      </svg>
      <p>Sorry, the latest images haven't loaded...</p>
      <a href="https://www.instagram.com/janehornby/" class="button">Go to Instagram</a>
    </div>`;
    }
    
});

function buildGallery(data){
  // console.log(data);
  var instaPlaceholder = document.querySelector('.instagram-placeholder');
  instaPlaceholder.parentNode.removeChild(instaPlaceholder);
  var instaGallery = document.querySelector('.instagram-gallery');

  var instaJsonImages = data;
  instaJsonImages.forEach(function(item, i) {
  if(i < 10){
      var thumb = item.media_url;
      var instaItem = document.createElement('div');
      instaItem.classList.add('instagram-gallery__item');
      var instaItemHtml = `<a href="${item.permalink}" target="_blank" style="background-image:url(${thumb});"><img src="/assets/placeholder.png" /></a>`;
      instaItem.innerHTML=instaItemHtml;
      instaGallery.appendChild(instaItem);
    }
  });
}
